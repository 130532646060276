import React, { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"
import Slider from "react-slick"
import Ativo1 from "../images/jornada-0.png"
import Ativo2 from "../images/jornada-1.png"
import Ativo3 from "../images/jornada-2.png"
import Ativo4 from "../images/jornada-3.png"
import Ativo5 from "../images/jornada-4.png"
import Ativo6 from "../images/jornada-5.png"

import Ativo7 from "../images/jornada-6.png"
import Ativo8 from "../images/jornada-7.png"
import Ativo9 from "../images/jornada-8.png"
import Ativo10 from "../images/jornada-9.png"
import Ativo11 from "../images/jornada-10.png"
import Ativo12 from "../images/jornada-11.png"
import Ativo13 from "../images/jornada-12.png"

const MamaeBebeSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [updateCount, setUpdateCount] = useState(0)
  const sliderRef = useRef(Slider)
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    swipeToSlide: true,
    centerPadding: '0px',
    variableWidth: true,
    className: "slider variable-width",
    afterChange: () =>
    setUpdateCount(updateCount + 1),
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  }
  return (
    <>
      <Slider {...settings} ref={sliderRef} className="overflow-hidden">
        <div>
          <div className="">
            <img src={Ativo1} alt="" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo2} alt="Consulta de 7 dias com a Ginecologista Obstétrica (se fez parto Theia).Visita da consultora de amamentação (4º dia).1ª consulta com pediatra (5 a 10 dias).Retorno com pediatra (7 a 10 dias após a primeira consulta)" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo3} alt="Consulta 40 dias com Ginecologista Obstétrica (alta obstétrica).Visita da consultora de amamentação (4º dia).1 consulta com Pediatra.Check-in psicológico pós parto" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo4} alt="1 consulta com nutricionista para mãe.1 consulta com preparador físico para a mãe.1 consulta com pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo5} alt="1 consulta com Pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo6} alt="1 consulta com Pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo7} alt="1 consulta com nutricionista para o bebê (IA).1 consulta com Pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo8} alt="1 consulta com Pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo9} alt="1 consulta com Pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo10} alt="1 consulta com odontologista para o bebê (*ou quando nascer o primeiro dente).1 consulta com Pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo11} alt="1 consulta com Pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo12} alt="1 consulta com Pediatra" />
          </div>
        </div>
        <div>
          <div className="">
            <img src={Ativo13} alt="1 consulta com Pediatra" />
          </div>
        </div>
      </Slider>
      <input
        onChange={(e) => {
          sliderRef.current.slickGoTo(e.target.value)
        }}
        value={slideIndex}
        type="range"
        min={0}
        max={12}
        className="jornada-range block lg:hidden mx-auto mt-12"
      />
      <input
        onChange={(e) => {
          sliderRef.current.slickGoTo(e.target.value)
        }}
        value={slideIndex}
        type="range"
        min={0}
        max={9}
        className="jornada-range hidden lg:block  mx-auto mt-12 mamae-bebe"
      />
      </>
  )
}

export default MamaeBebeSlider