import React, { useEffect, useState } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import './especialistas.scss'
import './theia-pacotes.scss'
import trackEvent from "../analyticsHelpers"
import RegularButton from '../components/atoms/buttons/RegularButton'
import ImgTopo from '../images/topo-mamaebebe.png'
import { goToApp } from "../utils"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import FirstCel from '../images/print-1-mb.png'
import SecondCel from '../images/print-2-mb.png'
import ThirdCel from '../images/print-3-mb.png'
import FontIcon from "../components/atoms/fontIcon/FontIcon"
import MamaeBebeSlider from "../components/mamaeBebeSlider"

const TheiaGravidez = () => {
 
  useEffect(() => {
    trackEvent("sitenovo-mamae-bebe-visualizou-tela")
  }, [])
  
  return (
    
    <Layout>
      <GatsbySeo
        title='Theia | Mamãe e Bebê'
        description='Desenhamos um programa para cuidar de você e do seu bebê com mais apoio, cuidado e saúde'
        language='pt-br'
        canonical='https://blog.theia.com.br/mamae-bebe'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/mamae-bebe',
          title: 'Theia | Gravidez',
          description: 'Desenhamos um programa para para cuidar de você e do seu bebê com mais apoio, cuidado e saúde',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Gravidez'
        }, {
          name: 'dc:description',
          content: 'Desenhamos um programa para cuidar de você e do seu bebê com mais apoio, cuidado e saúde'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'Desenhamos um programa para cuidar de você e do seu bebê com mais apoio, cuidado e saúde',
        },
         {
          name: 'keywords',
          content:  'puerpério, pediatria, amamentação, consultor do sono, gravidez, pós-parto, vacinação bebê'
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <section className="specialists-1 section-1 grid-wrapper-section items-center lg:items-start theia-gravidez-topo">
        <div className="text-specialist-content">
          <h1 className="text-dsTitle2 lg:text-dsTitle1 text-primary main-title mb-6 text-center lg:text-left">
            Mamãe Bebê
          </h1>
          <p className="text-center lg:text-left text-dsTextMedium text-textSecondary mb-10 lg:mb-12">
            Desenhamos um programa para você e seu bebê terem o apoio e cuidado que merecem no primeiro ano
          </p>
          <div className="flex justify-between lg:justify-start">
            <RegularButton
              onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-mamae-bebe-clicou-botao-comece-seu-programa')}
              label="Comece seu programa"
              extraClass="flex-1 btn-topo-especialistas"
            />
            <AnchorLink
              to="/mamae-bebe#mamae-bebe"
              onAnchorLinkClick={() => trackEvent("sitenovo-mamae-bebe-clicou-botao-saiba-mais")}
              className="btn-text-topo-especialistas"
            >
              Saiba mais
            </AnchorLink>
          </div>
        </div>
        <div className="img-specialist-content self-end internas">
          <img src={ImgTopo} alt="" style={{ width: '592px' }} />
        </div>
      </section>
      <section className="jornada-slider" id="mamae-bebe">
        <h2 id="section-especialistas-collapse" className="mt-8 lg:mt-20 specialists-title text-primary text-titleMedium lg:text-dsTitle2 text-center">
          Entenda como funciona a jornada Theia Mamãe Bebê
        </h2>
         <MamaeBebeSlider />
      </section>
      <section className="bg-bgCanvas section-3 grid-wrapper-section servicos-phones items-center lg:items-start">
        <div className="item1 text-center">
          <h2 className="text-titleMedium lg:text-dsTitle2 text-primary mt-12 mx-auto">
            Redefinimos a experiência do cuidado com mãe e bebê no primeiro ano de vida com mais apoio, respeito e autoconfiança
          </h2>
        </div>
        <div className="item2 mt-0 lg:mt-12">
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col items-center flex-1 lg:mt-16 mt-8">
              <FontIcon iconType="icon-Property-2Calendar" color="#582CC7" fontSize="34px" />
              <p className="p-cel text-textPrimary text-dsTextSmal text-center mt-4 mb-6">Nossa plataforma te indica o que fazer a cada passo </p>
              <img src={FirstCel} alt="" />
            </div>
            <div className="flex flex-col items-center flex-1 my-12 lg:my-0 mx-0 lg:mx-12">
              <FontIcon iconType="icon-Property-2Star" color="#582CC7" fontSize="34px" />
              <p className="p-cel text-textPrimary text-dsTextSmal text-center mt-4 mb-6">Te conecta a um time integrado de profissionais</p>
              <img src={SecondCel} alt="" />
            </div>
            <div className="flex flex-col items-center flex-1 lg:mt-16 mb-16 lg:mb-0">
              <FontIcon iconType="icon-Property-2Journey" color="#582CC7" fontSize="34px" />
              <p className="p-cel text-textPrimary text-dsTextSmal text-center mt-4 mb-6">E mostra conteúdos relevantes para o seu momento</p>
              <img src={ThirdCel} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="relative w-full flex flex-col gravidez-section grid-wrapper-section-4 items-center lg:items-start">
        <h2 className="text-white text-titleMedium lg:text-dsTitle2">
        Você pode pagar por consulta ou fazer o pacote Theia Mamãe bebê que inclui as consultas da sua jornada
        </h2>
        <div className="bg-white gravidez-content">
          <div className="flex flex-wrap flex-col lg:flex-row items-center">
            <div className="flex flex-col items-center flex-none gravidez-item">
              <FontIcon iconType="icon-Property-2Paper-Heart" color="#582CC7" fontSize="34px" extraClass="mb-3" />
              <p className="text-center text-textPrimary text-dsTextSmal">
                2 consultas com a Ginecologista Obstetra
              </p>
            </div>
            <div className="flex flex-col items-center flex-none gravidez-item">
              <FontIcon iconType="icon-Property-2Weight" color="#582CC7" fontSize="34px" extraClass="mb-3" />
              <p className="text-center text-textPrimary text-dsTextSmal">
                2 consultas com Nutricionista para mamãe e bebê
              </p>
            </div>
            <div className="flex flex-col items-center flex-none gravidez-item">
            
              <FontIcon iconType="icon-Property-2Shoe" color="#582CC7" fontSize="34px" extraClass="mb-3" />
              <p className="text-center text-textPrimary text-dsTextSmal">
                1 sessão com Preparador Físico
              </p>
            </div>
            <div className="flex flex-col items-center flex-none gravidez-item">
              <FontIcon iconType="icon-Property-2Calendar" color="#582CC7" fontSize="34px" extraClass="mb-3" />
              <p className="text-center text-textPrimary text-dsTextSmal">
                1 consulta com Odontologista para o bebê
              </p>
            </div>
            <div className="flex flex-col items-center flex-none gravidez-item">
              <FontIcon iconType="icon-Property-2Water-Drop" color="#582CC7" fontSize="34px" extraClass="mb-3" />
              <p className="text-center text-textPrimary text-dsTextSmal">
                1 consulta com Consultora de amamentação
              </p>
            </div>
            <div className="flex flex-col items-center flex-none gravidez-item">
              <FontIcon iconType="icon-Property-22-User" color="#582CC7" fontSize="34px" extraClass="mb-3" />
              <p className="text-center text-textPrimary text-dsTextSmal">
                1 conversa com Psicóloga online
              </p>
            </div>
            <div className="flex flex-col items-center flex-none gravidez-item lg:mx-auto">
              <FontIcon iconType="icon-Property-2Baby" color="#582CC7" fontSize="34px" extraClass="mb-3" />
              <p className="text-center text-textPrimary text-dsTextSmal">
                Todas as consultas com pediatra no primeiro ano de vida do bebê
              </p>
            </div>
          </div>
        </div>
        <RegularButton
            onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-mamae-bebe-clicou-botao-agende-consulta-agora')}
            label="Agende uma consulta agora"
            extraClass="my-6 z-10 mx-auto"
          />
        <div className="white-content">
         
        </div>
      </section>
    </Layout>
  )
}

export default TheiaGravidez